import React, { useState } from "react"
import { Card, Col, Row, List, Modal, Button } from 'antd';
import { Helmet } from "react-helmet"
import { LOGIN_URL } from "../utils/constant"

import "./pricing.css"
import recommend from "../images/recommend.png"
import qr from "../images/qr.png"
import indexStyles from "./index.module.css"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"


function Pricing(props) {
  const getCookie = (name) => {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if (arr = document.cookie.match(reg))
      return unescape(arr[2]);
    else
      return null;
  }
  const handleClick = () => {
    Modal.info({
      title: '下单须知',
      content: (
        <div>
          <p>图壳自 2019 年运行至今已有 2 年之久，为广大用户提供<span style={{ color: "red", fontWeight: "bold" }}> 免费、稳定、可靠 </span>的存储图片能力，感谢大家陪伴图壳一路走来！</p>
          <p>迫于免费带来的成本压力，为更长久的提供图片服务，特此推出付费版本，第一波用户将享受<span style={{ color: "red", fontWeight: "bold" }}> 9折 </span>的价格，功能 Beta 中，如需下单，请扫码备注”<span style={{ color: "red", fontWeight: "bold" }}> 图壳 </span>“。</p>
          <p><span style={{ color: "red", fontWeight: "bold" }}> 身份码 :</span> {getCookie("94tn")}</p>
          <p><img src={qr} class="qrImg" /></p>
        </div>
      ),
      onOk: function () {

      }
    });
  }

  const headers = [
    '广告',
    '私有存储',
    '存储类型',
    '存储时长',
    '请求流量限制',
    '请求带宽限制',
    '上传次数限制',
    '单文件大小限制',
    '镜像回源',
    '自定义域名',
    'HTTPS',
    '存储警告',
    '鉴黄',
    '上传历史',
    '删除图片',
    '费用',
    '开通方式',
  ];

  const reg = [
    '有',
    '无',
    'FTP',
    '永久',
    '共享流量',
    '共享 3M',
    '20张',
    '5M',
    '不支持',
    '不支持',
    '不支持',
    '不支持',
    '支持',
    '支持',
    '支持',
    '无',
    '注册',
  ];

  const basic = [
    '无广告',
    '10G',
    '云盘',
    '永久',
    '专享10G/月',
    '专享10M',
    '无限制',
    '10M',
    '不支持',
    '不支持',
    '不支持',
    '支持',
    '支持',
    '支持',
    '支持',
    '99/年 或 10/月',
    '下单',
  ];

  const advance = [
    '无广告',
    '100G',
    '云盘',
    '永久',
    '专享100G/月',
    '专享10M',
    '无限制',
    '20M',
    '支持',
    '支持',
    '支持',
    '支持',
    '支持',
    '支持',
    '支持',
    '999/年 或 100/月',
    '下单',
  ];

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="路过图床, 免费公共图床, 图片上传,图片外链, 原图保存, 全球CDN加速,新浪图床,免费图床,开源图床,个人图床,图床,php图床,图片外链,sm.ms,图床网" />
        <meta name="description" content="图壳诞生于2019年，是一款免费、安全、可靠的图片管理系统（简称图床），可支持多图上传、粘贴上传、URL上传，图片压缩、图片鉴黄、图片CDN、图床API等多种功能" />
        <meta name="imgkr" content="contact@imgkr.com" />
        <title>图壳费用 | 图壳，图片最稳定的家</title>
        <link rel="canonical" href="https://imgkr.com/" />
      </Helmet>
      <Navbar />
      <div className="pricing">
        <div className="priceDialog">
          <div className="form-container">
            <div className="site-card-wrapper">
              <Row gutter={16}>
                <Col span={6}>
                  <Card title="功能特性" bordered>
                    <List
                      size="small"
                      bordered={false}
                      dataSource={headers}
                      renderItem={(item, index) => <List.Item bordered={false} style={{ backgroundColor: ((index % 2 == 0) ? "#fdf9f5" : "#fff"), borderBottom: "0px" }}>{item}</List.Item>}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="注册用户" bordered style={{ marginRight: "-1px", marginLeft: "-1px" }}>
                    <List
                      size="small"
                      bordered={false}
                      dataSource={reg}
                      renderItem={(item, index) => <List.Item bordered={false} style={{ backgroundColor: ((index % 2 == 0) ? "#f1e8df" : "#fff"), borderBottom: "0px" }}>{(index == 16) ? <a href={LOGIN_URL}>登录</a> : item}</List.Item>} />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="基础版" bordered style={{ marginRight: "-1px", boxShadow: "0 0 10px 2px rgb(0 0 0 / 8%)", color: "#fffaeb", border: "1px solid #f2ddaf", zIndex: "1", marginTop: "-4px" }}>
                    <List
                      size="small"
                      bordered={false}
                      dataSource={basic}
                      renderItem={(item, index) => <List.Item bordered={false} style={{ backgroundColor: ((index % 2 == 0) ? "#f1e8df" : "#fff"), borderBottom: "0px" }}>{(index == 16) ? <a href="#" onClick={handleClick}>下单</a> : item}</List.Item>} />
                    <img src={recommend} style={{ position: "absolute", top: 0, left: 0 }} />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="高级版" bordered>
                    <List
                      size="small"
                      bordered={false}
                      dataSource={advance}
                      renderItem={(item, index) => <List.Item bordered={false} style={{ backgroundColor: ((index % 2 == 0) ? "#f1e8df" : "#fff"), borderBottom: "0px" }}>{(index == 16) ? <a href="#" onClick={handleClick}>下单</a> : item}</List.Item>} />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <footer id="foot" className={indexStyles.foot}>
        <Footer />
      </footer>
    </div>
  )
}

export default Pricing